@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face{font-family:"WorkSans-Regular";src:url("../public/assets/fonts/worksans/WorkSans-Regular.ttf")}
@font-face{font-family:"WorkSans-SemiBold";src:url("../public/assets/fonts/worksans/WorkSans-SemiBold.ttf")}
@font-face{font-family:"WorkSans-Medium";src:url("../public/assets/fonts/worksans/WorkSans-Medium.ttf")}

@font-face{font-family:"Quicksand-Regular";src:url("../public/assets/fonts/quicksand/Quicksand-Regular.ttf")}
@font-face{font-family:"Quicksand-Medium";src:url("../public/assets/fonts/quicksand/Quicksand-Medium.ttf")}
@font-face{font-family:"Quicksand-Bold";src:url("../public/assets/fonts/quicksand/Quicksand-Bold.ttf")}


.sticky{background:rgba(255,255,255,0.50);box-shadow:0 0 10px 5px rgb(0 0 0 / 10%);padding:5px 0}


select{background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e") no-repeat right 1rem center;background-size:1.1em; -moz-appearance:none;-webkit-appearance:none;-webkit-border-radius:0px;appearance:none;outline-width:0}

*, body{-webkit-font-smoothing:antialiased}

@media (min-width: 768px){
.md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
--tw-space-x-reverse: 0;
margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
}
@media (min-width: 1240px){
.md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
--tw-space-x-reverse: 0;
margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
}


footer::after{background:#000;content:"";width:38%;height:100%;float:left;position:absolute;left:0;top:0;bottom:0}
@media (min-width: 1280px){
.clipboard{clip-path:polygon(0 0, 100% 0, 95% 100%, 0% 100%);background:#000}
.clipboard1{clip-path:polygon(0 0, 100% 0, 90% 100%, 0% 100%)}
}
.wireframe_div{max-width:43.2%}

@media (max-width: 1800px){
.wireframe_div{max-width:42%}
}
@media (max-width: 1600px){
.wireframe_div{max-width:41%}
}
@media (max-width: 1400px){
.wireframe_div{max-width:40%}
}
@media (max-width: 1279px){
.wireframe_div{max-width:38%}
}
@media (max-width: 1023px){
.wireframe_div,
footer::after{display:none}
}

.blog_detail p{font-family:"WorkSans-Regular";font-size:1rem;color:#535353;padding-bottom:1rem;line-height:1.5}

.right_shap{clip-path:polygon(90% 0, 100% 0, 100% 100%, 0% 100%)}
.left_shap{clip-path:polygon(0 0, 20% 0, 100% 100%, 0% 100%)}

.sticky{--tw-backdrop-saturate:saturate(2);-webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);--tw-backdrop-blur:blur(40px);-webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)}

.slick-slider button{display:none !important}
.partner_slider .slider_img{aspect-ratio:3/2;object-fit:contain;height:170px;width:100%;padding:0 10px}
.portfolio_slider .slider_img{aspect-ratio:3/2;object-fit:contain;height:170px;width:100%;padding:0 10px}